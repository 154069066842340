.link, .link:visited {
  text-decoration: none;
  color: inherit;
}

.textButton {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  font-size: inherit;
  cursor: pointer;
}