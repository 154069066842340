.section {
  position: relative;
  overflow: hidden;

  h2 {
    font-weight: 400;
    text-align: center;
  }

  + .section {
    margin-top: 40px;
  }
  
  .background, 
  .background img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    z-index: 0;
  }

  .boxedContent {
    position: absolute;
    z-index: 1;

    bottom: 61px;
    left: 24px;

    max-width: 460px;

    @media (min-width: 1024px) {
      bottom: 102px;
      left: 91px;
    }
    
    .textBox {
      background: rgba(255,255,255,.95);
      padding: 21px;

      h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;

        @media (min-width: 1024px) {
          font-size: 17px;
        }
      }
      p {
        font-size: 14px;
        margin-top: 21px
      }
    }
  }
  
  .centralizedContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    
    p {
      width: 100%;
    }
    h2 {
      width: 100%;
      color: white;
      font-size: 34px;
    }
    .callToAction {
      margin-top: 100px;
    }
  }

  &.noBackground[style] {
    height: 50vh !important;
    .centralizedContent {
      padding: 55px 21px;
      h2 {
        color: black;
      }
      .callToAction {
        margin-top: 55px;
      }
    }
    
  }
}

.callToAction {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-size: 14px;
  padding: 8px 34px;
  height: 40px;
  text-decoration: none;
  margin-top: 14px;
  border: none;
  cursor: pointer;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.deliveriesWrapper {
  @media (min-width: 1024px){
    padding: 0 21px;
    grid-gap: 24px;
    margin-top: 21px;
    grid-row-gap: 24px; /* Adicionando o padding entre as linhas */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

  }
  
  
  .cardWrapper {
    width: 100%;
    height: auto;
    @media (min-width: 1024px){
      display: flex;
      width: 31.5%;
    }
    position: relative;
    
    

    .deliveryCard {
      background-size: cover;
      background-position: top center;
      display: block;
      padding-top: 123%;
      width: 100%;
      height: auto;
      position: relative;
      
    }

    .singleDeliveryCard{
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      padding-top: 56%;
      width: 100%;
      height: auto;
      position: relative;
    }

    .deliveryCardTextWrapper {
      color: rgb(255, 255, 255);
      position: absolute;
      z-index: 1;
      transform: rotate(-90deg);
      transform-origin: left top;
      top: calc(100% - 12px);
      left: 12px;
      width: 150%;

      .upperTitle {
        font-size: 14px;
      }

      h3 {
        letter-spacing: 0.58px;
        font-size: 52px;
        font-weight: 100;
        line-height: 1;
        margin: 0;
      }

      h4 {
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        transform: translateX(5px);
      }
    }
  }
}
.swimwearTitle {
  margin-top: 34px;
}
.singleDeliveryWrapper {
  .cardWrapper {
    .deliveryCard {
      padding-top: 100%;
    }
  }
  
}
