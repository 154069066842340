#RightArrow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 80%
  );
}

#LeftArrow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0) 100%
  );
}

.rec .ewqrkn {
  height: 5px;
  width: 5px;
  box-shadow: 0px 0px 1px 1px black;
  background-color: black;
  margin: 0px 3px;
}


.rec .kbDIuN {
  height: 5px;
  width: 5px;
  box-shadow: 0px 0px 1px 1px black;
  background-color: black;
  margin: 0px 3px;
}



.rec .hXQwGq,.cIWxKc  {
  height: 5px;
  width: 5px;
  margin: 0px 3px;
}

.rec .kbDIuN:hover,
.kbDIuN:focus {
  box-shadow: unset;
}

.rec .hXQwGq:hover,
.hXQwGq:focus {
  box-shadow: unset;
}

.rec .MuiButton-root.Mui-disabled {
  display: none;
}

.rec .sc-AxiKw {
  overflow: inherit;
}

.rec .rec-dot {
  height: 5px;
  width: 5px;
  margin: 0px 3px;

}

.rec .rec-dot_active {
  box-shadow: 0px 0px 1px 1px black;
  background-color: black;
}

.rec .rec-dot_active:focus {
  box-shadow: 0px 0px 1px 1px black;

}

.rec .rec-dot_active:hover {
  box-shadow: 0px 0px 1px 1px black ;

}

.rec .iICCav:hover {
  box-shadow: 0px 0px 1px 1px black ;
}